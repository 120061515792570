;(function() {

	var cw = window.cw = {};

		cw.init = function() {
			//this.header.init();
			this.nav.init();
			this.feature.init();
			this.accordion.init();
			this.lift.init();
			this.mobileNav.init();
			this.pressReleases.init();
		}

		cw.responsive = {};

		// @dev
		cw.responsive.info = function() {
			var infoDiv = $('<div style="color: #333; position: fixed; z-index: 99; right: 0px; bottom: 0px;" id="rwd-info"></div>');

			if(location.search.match(/[&|?]debug=1/)){

				$('html').addClass('debug');
				$('body').append(infoDiv);

				$(window).on('resize load', function() {
					infoDiv.html(
						'Viewport: ' + $(document).width() + '<br>' + 'Window: ' + window.innerWidth
					);
				});
			}
		};

		cw.responsive.info();

		cw.mobileNav = (function() {

			function init() {

				$('.mobile-nav__trigger').on('click', function(ev) {
					ev.preventDefault();

					if($('.mobile-nav').hasClass('mobile-nav--is-visible')) {
						$('body, .mobile-nav').removeClass('mobile-nav--is-visible');
					} else {
						$('body, .mobile-nav').addClass('mobile-nav--is-visible');
					}

				});

				$('.mobile-nav__item').each(function() {
					var sub = $('> .mobile-nav__sub', this),
					    button = $('<button>', { 'class': 'mobile-nav__sub-trigger' });

					button.on('click', function(ev) {
						ev.preventDefault();

						if(sub.hasClass('mobile-nav__sub--is-visible')) {
							sub.add(this).removeClass('mobile-nav__sub--is-visible');
						} else {
							sub.add(this).addClass('mobile-nav__sub--is-visible');
						}
					});

					if(sub.length) {
						$(this).prepend(button);
					}
				});



				$('.mobile-nav--has--sub').each(function() {
					var sub = $('> .test-mob-nav', this),
						button = $('<button>', { 'class': 'mobile-nav__sub-trigger' });

					button.on('click', function(ev) {
						ev.preventDefault();

						if(sub.hasClass('mobile-nav__sub--is-visible')) {
							sub.add(this).removeClass('mobile-nav__sub--is-visible');
						} else {
							sub.add(this).addClass('mobile-nav__sub--is-visible');
						}
					});

					if(sub.length) {
						$(this).prepend(button);
					}
				});
			}

			return { 'init': init };
		})();
		cw.nav = (function() {
			var exports = {};

			exports.init = function() {
				$('.main-nav').okayNav();
				//$('.sub-nav__container').okayNav();
				//$('.main-nav:not(.main-nav--header) #nav-main').okayNav();
			}

			return exports;
		})();

		/*cw.header = (function() {
			var exports = {};

			exports.init = function() {

				var minX = 1100;

				//if(window.innerWidth > minX) {}

				$(window).on('scroll', function() {
					if(window.innerWidth <= minX) {
						return;
					}

				    if(window.pageYOffset >= 200) {
				        $('.main-nav').addClass('main-nav--is-unpinned');
				        $('body').addClass('main-nav--is-unpinned');
				    } else {
				        $('.main-nav').removeClass('main-nav--is-unpinned');
				        $('body').removeClass('main-nav--is-unpinned');
				    }
				});



			}

			return exports;
		})();*/

		cw.pressReleases = (function() {
			var module = {};

			module.contents = null;

			module.yearSlider = null;
			module.monthSlider = null;

			module.onChange = function() {
				var yearRange = $.map(module.yearSlider.get(), function(num) { return parseInt(num, 10) });
				var monthRange = $.map(module.monthSlider.get(), function(num) { return parseInt(num, 10) });

				module.getNews(yearRange, monthRange);

			}

			module.getNews = function(yearRange, monthRange) {
				// Start AJAX request for each selected year
				// http://cewe.cytrus.de/index.php?id=70&type=7788&tx_news_pi1[overwriteDemand][timeRestriction]=01.01.2016&tx_news_pi1[overwriteDemand][timeRestrictionHigh]=31.12.2016

				console.log(yearRange, monthRange)

				this.contents = {};

				var requests = [];

				for(var year = yearRange[0]; year <= yearRange[1]; year++) {
				  var timeRestrictionLow = '01.' + monthRange[0] + '.' + year;
				  var timeRestrictionHigh = '31.' + monthRange[1] + '.' + year;

				  var request = (function(y) {
					return $.get('http://cewe.cytrus.de/index.php?id=70&type=7788&tx_news_pi1[overwriteDemand][timeRestriction]='+ timeRestrictionLow +'&tx_news_pi1[overwriteDemand][timeRestrictionHigh]=' + timeRestrictionHigh, function(result) {
			  			module.contents[y] = result;
			 	 	});
				  })(year);

				  requests.push(request);

				}

				$.when.apply($, requests).done(function() {
					module.insertNews(Array.prototype.slice.call(arguments));
				});

			}

			module.insertNews = function(data) {
				var newsList = $('.news-list__items');

				newsList.html('');

				$.each(this.contents, function(i, el) {
					newsList.prepend($('.news-list__item', el));
				});
			}

			module.init = function() {

				if(!$('.daterange-slider--year').length) {
					return;
				}

				// Year Slider
				var currentYear = (new Date()).getFullYear();

				this.yearSlider = noUiSlider.create($('.daterange-slider--year').get(0), {
				  start: [ currentYear - 1, currentYear ],
				  connect: true,
				  snap: true,
				  range: {
				    'min': currentYear - 4,
				    '25%': currentYear - 3,
				    '50%': currentYear - 2,
				    '75%': currentYear - 1,
				    'max': currentYear
				  },
				  pips: {
				    'mode': 'steps',
				    'density': 100
				  }
				});

				this.yearSlider.on('change', this.onChange);

				// Month Slider
				this.monthSlider = noUiSlider.create($('.daterange-slider--month').get(0), {
				  start: [ '1', '12' ],
				  connect: true,
				  snap: true,
				  range: {
				    'min': 1,
				    '9.090909090909092%': 2,
				    '18.181818181818183%': 3,
				    '27.272727272727273%': 4,
				    '36.36363636363637%': 5,
				    '45.45454545454546%': 6,
				    '54.54545454545455%': 7,
				    '63.63636363636364%': 8,
				    '72.72727272727273%': 9,
				    '81.81818181818183%': 10,
				    '90.90909090909092%': 11,
				    'max': 12
				  },
				  pips: {
				    'mode': 'steps',
				    'density': 100
				  }
				});

				this.monthSlider.on('change', this.onChange);

			}

			return module;
		})();

		cw.feature = (function() {
			var exports = {};

			exports.init = function() {
				this.actions();
			}

			exports.actions = function() {
				var main = $('.main'),
				    feature = $('.feature');

				/*var scrollController = new ScrollMagic.Controller({ addIndicators: true });

				new ScrollMagic.Scene({
					duration: '10%',
					offset: 100
				}).addTo(scrollController);
				*/

				/*
				$(window).on('load.cw.feature', function(ev) {

					// @todo throttle event
					var lastScrollTop = 0;
					var initialFeatureHeight = $('.feature').outerHeight();
					var minFeatureHeight = initialFeatureHeight / 2;

					$(window).on('scroll.cw.feature', function(ev) {

						var mainTop = main[0].getBoundingClientRect().y;

						var scrollTop = $(this).scrollTop();

						var ratio = $(document).width() / (mainTop - 100);

						// console.log('ratio:', ratio)

						console.log(initialFeatureHeight)

						// scroll down
						if(scrollTop > lastScrollTop) {


							if(feature.height() > minFeatureHeight) {
								feature.height(feature.height() - 2)
							}

							if(ratio > 3.7) {
								$('.feature').attr('class', 'feature');
							}
							if(ratio > 3.9) {
								$('.feature').attr('class', 'feature feature--step-1');
							}
							if(ratio > 4.1) {
								$('.feature').attr('class', 'feature feature--step-2');
							}
							if(ratio > 4.3) {
								$('.feature').attr('class', 'feature feature--step-3');
							}
						}
						// scroll up
						else {

							if(feature.height() < initialFeatureHeight) {
								feature.height(feature.height() + 2)
							}

							if(ratio > 0) {
								if(ratio < 5 ) {
									$('.feature').attr('class', 'feature feature--step-3');
								}
								if(ratio < 3.9) {
									$('.feature').attr('class', 'feature feature--step-2');
								}
								if(ratio < 4.1) {
									$('.feature').attr('class', 'feature feature--step-1');
								}
								if(ratio < 4.3) {
									$('.feature').attr('class', 'feature');
								}
							}
						}

						lastScrollTop = scrollTop;

					});

				});
				*/
			}

			return exports;
		})();

		/*cw.feature = (function() {
			var exports = {};

			exports.init = function() {
				$(window).on('load', function() {
					exports.generateBlur();
				});
				this.scrollActions();
			}

			exports.generateBlur = function() {
				var featureImages = $('.feature__image img');

				featureImages.each(function() {
					var canvas = document.createElement('canvas');

					canvas.className = 'feature__image--blurred';

					StackBlur.image(this, canvas, 20)

					$(this).parents('.feature__image').prepend(canvas);

				});

			}

			exports.scrollActions = function() {
				var featureContent = $('.feature__content'),
				    main = $('.main');

				// @todo throttle event
				var lastScrollTop = 0;

				$(window).on('scroll.cw.feature', function(ev) {

					var mainTop = main[0].getBoundingClientRect().y;

					var scrollTop = $(this).scrollTop();

					var ratio = $(document).width() / (mainTop - 100);

					// scroll down
					if(scrollTop > lastScrollTop) {
						if(ratio > 3.3) {
							$('.feature').attr('class', 'feature');
						}
						if(ratio > 3.6) {
							$('.feature').attr('class', 'feature feature--step-1');
						}
						if(ratio > 3.7) {
							$('.feature').attr('class', 'feature feature--step-2');
						}
						if(ratio > 3.8) {
							$('.feature').attr('class', 'feature feature--step-3');
						}
					}
					// scroll up
					else {
						if(ratio > 0) {
							if(ratio < 5 ) {
								$('.feature').attr('class', 'feature feature--step-3');
							}
							if(ratio < 3.6) {
								$('.feature').attr('class', 'feature feature--step-2');
							}
							if(ratio < 3.7) {
								$('.feature').attr('class', 'feature feature--step-1');
							}
							if(ratio < 3.8) {
								$('.feature').attr('class', 'feature');
							}
						}
					}

					lastScrollTop = scrollTop;

				});
			}

			return exports;
		})();
		*/

		cw.accordion = (function() {
			var exports = {};

			exports.init = function() {

				if(!$('.accordion').length) {
					return;
				}

				$('.accordion').next(':not(.accordion)').prev('.accordion').addClass('accordion--is-last');

				$('.accordion:not(.accordion--is-active) .accordion__content').hide();
				// $('.accordion.accordion--is-active').removeClass('accordion--is-active');
				// $('.accordion .accordion__content').hide();

				$('.accordion').each(function() {
					var _self = $(this);

					if(_self.attr('data-toggle-all')) {
						var toggleAllButton = $('<div>', {
							'role': 'button',
							'class': 'accordion__toggle-all',
							'text': _self.attr('data-toggle-all')
						});

						toggleAllButton.on('vclick', function(ev) {
							ev.preventDefault();
							ev.stopPropagation();

							if(!_self.hasClass('accordion--is-active')) {
								_self.addClass('accordion--is-active');
								$('.accordion', _self).addClass('accordion--is-active');
								$('.accordion__content', _self).stop(true, true).slideDown(400);
							} else {
								_self.removeClass('accordion--is-active');
								$('.accordion', _self).removeClass('accordion--is-active');
								$('.accordion__content', _self).stop(true, true).slideUp(400);
							}

						});

						$('> .accordion__header', _self).append(toggleAllButton);
					}

					$('> .accordion__header', _self).on('vclick', function() {
						if(!_self.hasClass('accordion--is-active')) {
							_self.addClass('accordion--is-active');
							$(this).next('.accordion__content').stop(true, true).slideDown(400);
						} else {
							_self.removeClass('accordion--is-active');
							$(this).next('.accordion__content').stop(true, true).slideUp(400);
						}
					});

				});

			}

			return exports;
		})();

		cw.lift = (function() {
			var exports = {};

			exports.init = function() {

				var lift = $('.lift');

				$.extend($.easing, {
					easeOutSine: function (x, t, b, c, d) {
						return c * Math.sin(t/d * (Math.PI/2)) + b;
					}
				});

				var waypoint = new Waypoint({
					element: $('.content-box')[0],
					offset: 'bottom-in-view',
					handler: function(direction) {
						if(direction === 'down') {
							lift.addClass('lift--visible');
						} else {
							lift.removeClass('lift--visible');
						}
					}
				});

				lift.on('vclick', function(ev) {
	    			$('html, body').stop(true, true).animate({scrollTop: 0}, ($(this).offset().top / 4), 'easeOutSine');

					ev.preventDefault();
				});
			}

			return exports;
		})();

		cw.init();

})();