function forsubaccordion() {
    $('.forsubaccordion').unwrap();
    $('.forsubaccordion').unwrap();

    $('#pages5020').wrapInner('<div class="forsubaccordion" style="margin-top:0;"/>');
    $('#pages6020').wrapInner('<div class="forsubaccordion" style="margin-top:0;"/>');

    $('.contenttable,.figures,.halfsingle,.analystcov,.contenttable').each(function () {
        $(this).wrap('<div class="responive_table_wrap"></div>')
    });
    //if($('.forsubaccordion').length < 0){}


    $('.forsubaccordion .accordion .accordion__header').click(function (e) {
        var curr = $(this).next('.accordion__content').css('display');
        if (curr == 'none') {
            $(this).next('.accordion__content').slideDown(300);
            $(this).addClass('isActive');
        }
        else {
            $(this).next('.accordion__content').slideUp(300);
            $(this).removeClass('isActive');
        }
    });

    var add_Swipe_symbol = '<div class="swipe-symbol"></div>';
    $(document).find('.responive_table_wrap').each(function () {
        $(this).prepend(add_Swipe_symbol);
    });

    $('.swipe-symbol').on('touchstart click', function () {
        $(this).fadeOut(500);
    });

};
var count = 0;
function responsiveTable() {
    if (count == 0) {
        $('.contenttable,.figures,.halfsingle,.analystcov,.contenttable').each(function () {
            $(this).wrap('<div class="responive_table_wrap"></div>')
        });
        $('.swipe-symbol').on('touchstart click', function () {
            $(this).fadeOut(500);
        });
        count = 1;
    }
}

$(document).ready(function (e) {
    $(".popuplink").fancybox({
        maxWidth: 800,
        maxHeight: 600,
        fitToView: false,
        width: '70%',
        height: '70%',
        autoSize: false,
        closeClick: false,
        openEffect: 'none',
        closeEffect: 'none'
    });
    $(".accordion__toggle-all").on("click", function () {
        $(".accordion__header").trigger("click");
    });

    var hash = window.location.hash;
    if(hash){
        $(hash).prev('.accordion__header').trigger('click');
        $(".accordion--is-active").addClass("active_accordion");
        setTimeout(function(){
            $(window).scrollTop($('.active_accordion').offset().top);
        }, 2000);

    }




    $('.level_01 ul li a.hash_check').click(function (e) {
        $(".mobile-nav").removeClass("mobile-nav--is-visible");
        $("body").removeClass("mobile-nav--is-visible");
        e.preventDefault();
        window.location.hash = "";
        var attrId = $(this).attr('href');
        attrId = attrId.substring(attrId.indexOf("#") + 1);

        // $('.accordion__header').each(function () {
        //     var dis = $(this).next('.accordion__content').css('display');
        //     if (dis == "block") {
        //         $(this).trigger('click');
        //     }
        // });
        var trig = $('#' + attrId).prev('.accordion__header');

        trig.trigger('click');
        $('html, body').stop().animate({
            scrollTop: trig.offset().top
        }, 1000);
    });

});
